@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.meta__wrapper {
  font-style: italic;
}

.meta__separator {
  color: var(--sp-color-neutral-accent-one);
}

.author-card {
  font-style: italic;
  margin-bottom: 40px;
}
