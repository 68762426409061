@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.share__wrapper {
  margin-bottom: $spacer;
}

button.react-share__ShareButton {
  margin: 8px;
}
