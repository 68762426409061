@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__hero-image-text {
  &.landscape {
    background: linear-gradient(90deg, $white 35%, var(--sp-color-primary) 35%);
    padding: 35px;

    .row {
      margin: 25px 0;
    }
  }

  &.portrait {
    background: linear-gradient(
      90deg,
      var(--sp-color-secondary) 38%,
      var(--sp-color-primary) 35%
    );

    .row {
      margin: 60px 0;
    }

    .hero-image {
      bottom: 50px;

      @media (max-width: 992px) {
        bottom: 0;
        display: flex;
        justify-content: center;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    background: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
  }

  .hero-image {
    position: relative;
    z-index: 100;
  }

  .hero-text {
    background: var(--sp-color-primary);
    color: #fff;
    height: 100%;
    padding: 20px 20px 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(lg) {
      padding-left: 0;
      box-shadow: -125px 0 0 60px var(--sp-color-primary),
        250px 0 0 60px var(--sp-color-primary);
    }

    .eyebrow {
      color: $yellow;
      margin: 0;
    }

    h2 {
      font-weight: bold;

      @include media-breakpoint-down(lg) {
        font-size: calc(1.375rem + 0.5vw);
      }
    }

    h3 {
      font-weight: 300;
      text-transform: initial;

      @include media-breakpoint-down(lg) {
        font-size: calc(1.2875rem);
        line-height: 32px;
      }
    }

    h2,
    h3 {
      margin-top: 0;
      margin-bottom: 8px;
      color: #fff;
    }

    a {
      font-family: var(--sp-font-secondary);
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #fff;
      font-weight: bold;

      &.button {
        text-align: left;
        color: $white;
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
        font-size: 14px;
        font-weight: bold;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
