@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__imagetext {
  display: flex;
  margin: $spacer * 2 0 $spacer * 3 0;

  .callout {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    &.image-right {
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
      }

      .callout__content {
        left: 0;
      }
    }

    ul li a,
    ol li a {
      color: var(--sp-color-link);
      border-bottom: 1px solid #ccc;
  
      &:hover {
        color: var(--sp-color-primary);
        border-bottom: 1px solid #012169;
        text-decoration: none;
      }
    }  

    .content-wrapper {
      width: 100%;
      // height: 100%;

      @include media-breakpoint-up(md) {
        width: 33.3%;
        background-color: var(--sp-color-secondary);
      }
    }

    &__content {
      width: 100%;
      font-size: 1rem;
      position: inherit;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 0;

      @include media-breakpoint-up(md) {
        background-color: $white;
        width: calc(133.3% + 4.4em);
        height: fit-content;
        padding: $spacer;
        position: relative;
        margin: 2.2em;
        left: calc((100% - 4rem) * (-1));

        &:before {
          content: "";
          background: var(--sp-color-secondary);
          position: absolute;
          top: -2.2rem;
          bottom: -2.2rem;
          left: -2.2rem;
          right: -2.2rem;
          z-index: -1;
        }
      }

      h3 {
        margin: 0;
      }
    }

    &__image {
      height: 100%;
      align-self: center;
      overflow: hidden;
      width: 100%;
      margin-bottom: $spacer;
      border-left: solid 33.3% var(--sp-color-secondary);

      @include media-breakpoint-up(md) {
        width: 66.6%;
        margin-bottom: 0;
      }

      .image {
        object-fit: cover;
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
      }
    }

    &__heading {
      font-family: var(--sp-font-secondary);
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.5rem;
      color: var(--sp-color-primary);
      margin-top: 0;
    }
  }

  .col-md-8,
  .col-md-4 {
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    margin-top: $spacer;
  }
}

// override/additional styling for 2/3 columns
.paragraph__twocolumn,
.paragraph__threecolumn {
  .paragraph__imagetext {
    .callout {
      flex-direction: column !important;


      @include media-breakpoint-up(md) {
        &__content {
          position: initial;
          width: 90%;
          margin: 0 5% 5% 5%;
  
          &:before {
            display: none;
          }
        }
      }

      &__image {
        height: inherit;
        align-self: center;
        overflow: hidden;
        width: 100%;

        @include media-breakpoint-up(lg) {
          height: 100%;
        }
      }

      .content-wrapper {
        width: 100%;
      }

      .button {
        padding: 5px;
      }
    }
  }
}
