@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.aside-bottom {
  margin-top: $spacer * 5;

  .aside-label {
    margin-top: $spacer * 2;
  }

  .series-list {
    padding: 0;
    list-style-type: none;
  }
}

.events-featured-image {
  margin-bottom: 64px;
}

.event-status {
  color: var(--sp-color-accent-two);
}

.speaker {
  font-family: var(--sp-font-secondary);
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  color: $dark-gray;
}

.category {
  font-family: var(--sp-font-secondary);
  font-size: 14px;
  color: $dark-gray;
}

.event-content {
  margin-top: 40px;

  @include media-breakpoint-up(md) {
    padding-left: $spacer * 3;
    margin-top: 0px;
  }
}
