@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.story-wrapper {
  @include media-breakpoint-up(md) {
    margin-top: -190px;
  }
}

// TODO: I bet this gatsby-image-wrapper needs updated
.story-featured-image .gatsby-image-wrapper {
  max-height: 575px;
}

.story-meta-wrapper {
  max-width: 1048px;
  position: relative;
  margin: 0 auto;
  background: #fff;

  @include media-breakpoint-up(md) {
    padding: $spacer * 2;

    &:before {
      width: 50vw;
      background-color: var(--sp-color-secondary);
      content: "";
      height: 100%;
      position: absolute;
      z-index: -1;
      bottom: 15%;
      right: 50%;
    }
    &:after {
      width: 50vw;
      background-color: var(--sp-color-secondary);
      content: "";
      height: 100%;
      position: absolute;
      z-index: -1;
      bottom: 15%;
      left: 50%;
    }
  }

  .eyebrow {
    margin-bottom: 0;
  }

  .heading {
    margin-top: 0;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
    }
  }

  h2.heading {
    @include media-breakpoint-down(md) {
      font-weight: 300 !important;
    }
  }

  .share__wrapper {
    h3.heading {
      font-size: 12px;
    }
  }
}

.story-paragraph-wrapper {
  max-width: 1048px;
  position: relative;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    // top: -190px;
    padding: 0 $spacer * 2;
  }
}

.story-footer-wrapper,
.story-related-wrapper {
  max-width: 1048px;
  margin: 0 auto;
  padding: $spacer * 2;
}

.node-teaser__story {
  padding: $spacer*1.5 0; 
  border-bottom: solid 1px var(--sp-color-neutral-accent-one);
  word-break: break-word;
  margin-bottom: 0;


  .news-featured-image {
    margin-bottom: $spacer;
  }

  .node-teaser__meta {
    margin-top: $spacer * 0.5;

    &>*,
    .date-lineview {
      display: inline;
    }
  }

  .node-teaser__meta--authors a {
    color: $medium-gray;
    text-transform: none;

    &:hover {
      background-color: transparent;
      color: var(--sp-color-primary);
      text-decoration: underline;
    }
  }
}