@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";

.node-teaser__news {
  margin-bottom: $spacer;

  .date {
    display: inline-block;
  }

  .news-featured-image {
    margin-bottom: $spacer;
  }
}
