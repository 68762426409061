@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.blog-featured-image {
  display: flex;
  justify-content: center;

  @include media-breakpoint-up(md) {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
  }

  .lazyload-wrapper {
    z-index: -100;

    img {
      width: 85vw;
      object-fit: cover;
      box-shadow: 800px 200px var(--sp-color-secondary),
        -800px -300px var(--sp-color-secondary);
      z-index: -100;

      @include media-breakpoint-down(xl) {
        max-height: 600px;
      }

      @include media-breakpoint-down(lg) {
        max-height: 500px;
      }

      @include media-breakpoint-down(md) {
        box-shadow: 400px 200px var(--sp-color-secondary),
          -400px -300px var(--sp-color-secondary);
        max-height: 390px;
      }

      @include media-breakpoint-down(sm) {
        box-shadow: -150px -90px var(--sp-color-secondary),
          150px -90px var(--sp-color-secondary);
        max-height: 200px;
      }
    }
  }
}

.blog-post {
  &__content {
    position: relative;
    margin: 0;
    padding: 0;
    background: $white;

    @include media-breakpoint-up(md) {
      padding: $spacer $spacer * 3;
      margin: -$spacer * 5 auto 0 auto;
      max-width: 1048px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    .eyebrow {
      margin-bottom: 0;
      margin-top: $spacer * 2;
    }

    .heading {
      margin-top: 0;
    }
  }

  &__content--noimage {
    @include media-breakpoint-up(md) {
      margin: 0 auto;
    }
  }
}

.node-teaser__blog {
  padding: 1.1em 0;
  border-bottom: solid 1px var(--sp-color-neutral-accent-one);
  word-break: break-word;

  .node-teaser__meta {
    margin-top: $spacer * 0.5;

    & > *,
    .date-lineview {
      display: inline;
    }
  }

  .node-teaser__meta--authors a {
    color: $medium-gray;
    text-transform: none;
    font-family: var(--sp-font-secondary);

    &:hover {
      background-color: transparent;
      color: var(--sp-color-primary);
      text-decoration: underline;
    }
  }
}
