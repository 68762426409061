@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.profile {
  padding-top: $spacer*2;

  svg {
    margin-right: 5px;
    color: var(--sp-color-secondary);
    width: 24px;
    height: 24px;
  }

  .meta-box {
    font-size: 0.9rem;
  }

  .field-container:first-of-type {
    margin-top: 1em;
  }

  .education {
    display: block;
  }

  .heading {
    margin-bottom: 0;
  }

  &__pronouns {
    color: var(--sp-color-primary);
    margin-bottom: 0;
  }

  &__address span {
    display: block;
    font-style: normal;
    font-family: var(--sp-font-secondary);
  }

  &__position {
    font-family: var(--sp-font-secondary);
    font-style: italic;
    color: var(--sp-color-primary);
    font-size: 1.25rem;
  }

  &__personal-link {
    font-family: var(--sp-font-secondary);
  }

  &__related-content-item {
    border-bottom: solid 1px $between-med-light-gray;
    padding: $spacer*1.5 0;

    p {
      margin-bottom: 8px;

      &.eyebrow {
        margin-bottom: 0;
      }
    }


    // TODO: Is this leftover from an old html structure? I don't see it anywhere
    time {
      font-family: var(--sp-font-secondary);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &:last-of-type {
      border: none;
    }
  }

  .profile__image {
    margin-bottom: $spacer*2;
  }

  h2.heading:first-of-type {
    margin-top: $spacer*2;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  .accordion-toggle {
    text-transform: capitalize;
  }
}

.profile-teaser {
  margin-bottom: $spacer*2;
  font-family: var(--sp-font-secondary);

  svg {
    margin-right: 8px;
    color: var(--sp-color-secondary);
  }

  &__name {
    margin-bottom: 0;
    margin-top: $spacer;
    line-height: 1.3;

    a {
      color: var(--sp-color-secondary);
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: $spacer*0.5;
      display: inline-block;
    }
  }

  &__role {
    font-family: var(--sp-font-secondary);
    font-weight: 600;
    font-size: 1rem;
    text-transform: initial;
    color: $dark-gray;
		line-height: 1.5em !important;
  }

  &__image {
    max-width: 200px;
  }

  &__bio, &__email, &__phone {
    font-family: var(--sp-font-primary);
  }

  h4.heading {
    margin-top: 0;
  }
  
  @include media-breakpoint-down(md) {
    padding-left: 20px;
    padding-right: 20px;

    &__image {
      max-width: 550px;
      margin: 0 auto; 
    }
  }
}

button.reveal-more {
  border-radius: 0;
  color: $dark-blue;
  background-color: $white;
  margin: $spacer 0;
  padding: $spacer*0.3 $spacer*0.5;
  border: 4px solid var(--sp-color-secondary);
  display: inline-flex;
  align-items: center;

  &:hover, &:focus {
    background-color: var(--sp-color-secondary);
    transition: background-color 0.2s ease-out;
    color: $white;
    text-decoration: none;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.4);
  }
}
