@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


//TODO: Hard coded color. Seems also like a good candidate for a refactoring. It isn't used anywhere but one place?
.categories__name {
  font-family: var(--sp-font-secondary);
  font-size: 0.9rem;
  font-weight: 400;

  a {
    color: #0577b1;
  }
}
