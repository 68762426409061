@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__callout {
  background: var(--sp-color-secondary);
  color: white;
  margin-top: $spacer*7;
  margin-bottom: $spacer*2;
  box-shadow: 400px 0 var(--sp-color-secondary);
  max-width: 100vw;

  @include media-breakpoint-down(lg) {
    box-shadow: 0px 0 var(--sp-color-secondary);
    background: transparent;
    overflow-x: hidden;
    max-width: 100vw;
  }

  a:not(.button) {
    color: $yellow;

		&:hover {
			color: $white;
		}
  }

  .callout {
    background-color: var(--sp-color-primary);
    padding: $spacer*2;
    position: relative;
    display: flex;
    flex-direction: column;

    ul li a,
    ol li a {
      color: $yellow;
      border-bottom: 1px solid #ccc;
  
      &:hover {
        color: $white;
        border-bottom: 1px solid #012169;
        text-decoration: none;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      top: -40px;
      left: 60px;

      &__image {
        width: 25%;
				height: fit-content;
        margin-right: $spacer;
      }

      .content-wrapper {
        width: 75%;
      }
    }

    &__image {
      .lazyload-wrapper {
        display: flex;
        justify-content: center;
  
        img {
          margin-top: -80px;
          width: 100%;
          height: 100%;
        }
      }
    }

    &__heading {
      color: $white;
      margin-top: 0;
    }
  }

  // external links
  a[href*="http"]:not([href*="files"]) {
    &:hover::after {
      background-color: var(--sp-color-primary);
    }
  }
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .paragraph__callout {
    background: transparent;
    box-shadow: none;
    margin-top: 0;

    @include media-breakpoint-down(md) {
      margin-top: $spacer * 2;
    }

    .callout {
      flex-direction: column;
      left: 0;
      top: 0;
      margin: 0.5rem ($spacer * 2) ($spacer * 2) ($spacer * 2);
      box-shadow: 30px 30px 0 0 var(--sp-color-secondary), -30px 30px 0 0px var(--sp-color-secondary);

      .content-wrapper {
        width: 100%;

        .callout__heading {
          margin-top: 0.5rem;
        }
      }

      &__image {
        width: 100%;
        height: auto;
        align-self: center;
        margin-right: 0;
      }
    }
  }
}

//For callouts in 3 column row or the 1/3 column of a 2 column row ONLY.
.paragraph__threecolumn {
  .paragraph__callout {
    .callout {
      margin: $spacer !important;
      padding: $spacer !important;
    }
  }
}
.paragraph__twocolumn {
  .col-md-4 > .paragraph__callout {
    .callout {
      margin: $spacer !important;
      padding: $spacer !important;
    }
  }
}
