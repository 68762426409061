@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.news-title {
  h1,
  h2 {
    margin: 0 0 40px;
  }

  h2 {
    // TODO: Update color value
    color: #000;
    margin-bottom: 1em;
  }

  .content-type-tag {
    font-family: var(--sp-font-secondary);
    font-size: 18px;
    line-height: 32px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $dark-gray;
  }
}

.main-content--news {

    .row {
        margin-top: $spacer * 3;
    }

    .slideshow {
        margin-top: 50px;
    }
}

// TODO: This is being used globally for field-label throughout the base - should it be here?
.field-label {
  font-family: var(--sp-font-secondary);
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 1rem;

  &:first-of-type {
    margin-top: 0;
  }
}

.featured-image.news {
  margin-bottom: 40px;
}
