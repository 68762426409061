@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__publicationlist {
    ul {
        li {
            margin-bottom: $spacer;
            a {
                font-weight: bold;
            }
        }
    }
    ul li a,
    ol li a {
      color: var(--sp-color-link);
      border-bottom: 1px solid #ccc;
  
      &:hover {
        color: var(--sp-color-primary);
        border-bottom: 1px solid #012169;
        text-decoration: none;
      }
    }
}